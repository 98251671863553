<template>
  <div class="index-wrapper">
      <div class="bg"></div>
      <!--<div class="overlay"></div>-->
      <div class="index-main">
        <div class="tnx-logo">
          <img src="@/assets/tnx1024x1024.png" alt="TNX"/>
          <h1>添实科技</h1>
        </div>
        <p>•&nbsp;致力于为小微企业提供信息化解决方案&nbsp;•</p>
        <HeaderComponents :current-index="0" />
      </div>
  </div>
</template>

<script>
    import HeaderComponents from "@/components/Layout/Header";
    export default {
      name: 'IndexPage',
      components: {HeaderComponents},
      data() {
        return {}
      }
    }
</script>

<style lang="scss" scoped>

@keyframes bg {
  0% {
    transform: translate3d(0,0,0);
  }

  100% {
    transform: translate3d(-2250px,0,0);
  }
}

.bg {
  background-size: 2250px auto;
  width: 6750px;
  animation: bg 60s linear infinite;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  background: #348cb2 url(@/assets/images/payment.png) repeat-x bottom left;
  height: 100%;
  left: 0;
  opacity: 1;
  position: fixed;
  top: 0;
}

@keyframes index-wrapper {
  0% {
    transform: translate3d(0,1em,0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}

.index-wrapper {
  height: 100vh;
  width: 100%;
}

.index-main {
  animation: index-wrapper 1s 2.25s forwards;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .tnx-logo {
    //width: 18em;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 999;

    img {
      width: 3em;
      height: 1.2em;
      margin-bottom: 4px;
    }

    h1 {
      font-size: 4.35em;
      font-weight: 900;
      letter-spacing: -0.035em;
      line-height: 1em;
      color: #003366;
    }
  }

  p {
    font-size: 1.25em;
    margin: 0.75em 0 0.25em 0;
    opacity: 0.75;
    color: #003366;
  }

}


/** 手机适配 开始 */
@media screen and (max-width: 480px) {
  @keyframes bg {
    0% {
      transform: translate3d(0, 0, 0);
    }

    100% {
      transform: translate3d(-412.5px, 0, 0);
    }
  }

  .bg {
    background-size: 412.5px auto;
    width: 1237.5px;
  }

  .index-main {

    .tnx-logo {
      h1 {
        font-size: 3.5em;
      }
    }

    p {
      font-size: 0.7em;
    }
  }
}
/** 手机适配 结束 */
</style>
