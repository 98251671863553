<template>
    <nav :class="currentIndex === 0?'index-nav':'oth-nav'">
      <div class="overlay"></div>
      <ul>
        <li v-if="currentIndex !== 0">
          <router-link to="/">
            <el-icon class="icon-class"><HomeFilled /></el-icon>
            <span class="label">首页</span>
          </router-link>
        </li>
        <li v-if="currentIndex !== 1">
          <router-link to="/about">
            <el-icon class="icon-class"><Discount /></el-icon>
            <span class="label">关于我们</span>
          </router-link>
        </li>
        <li v-if="currentIndex !== 2">
          <router-link to="/service">
            <el-icon class="icon-class"><Grid /></el-icon>
            <span class="label">服务内容</span>
          </router-link>
        </li>
        <li v-if="currentIndex !== 3">
          <router-link to="/contact">
            <el-icon class="icon-class"><Location /></el-icon>
            <span class="label">联系方式</span>
          </router-link>
        </li>
      </ul>
    </nav>
</template>

<script>
import {HomeFilled, Location, Grid, Discount} from "@element-plus/icons-vue"
export default {
  name: "HeaderComponents",
  components: { HomeFilled, Location, Grid, Discount },
  props: {
    currentIndex: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>

nav.index-nav {
  margin: 1.5em 0 0 0;
}

nav.oth-nav {
  padding: 20px 0 0 0;
  //background-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.5) 75%);
  background: #348cb220;
  color: #fff;
  position: fixed;
  //z-index: 9999;
  top: 0;
  width: 100%;
  text-align: center;
  height: 100px;
}

@keyframes nav-icons {
  0% {
    transform: translate3d(0,1em,0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}

nav li {
  animation: nav-icons 0.5s ease-in-out forwards;
  backface-visibility: hidden;
  transform: translate3d(0,0,0);
  display: inline-block;
  height: 5.35em;
  line-height: 5.885em;
  opacity: 0;
  position: relative;
  top: 0;
  width: 5.35em;
  list-style: none;
}

nav li:nth-child(1) {
  animation-delay: 2.5s;
}

nav li:nth-child(2) {
  animation-delay: 2.75s;
}

nav li:nth-child(3) {
  animation-delay: 3s;
}

nav li:nth-child(4) {
  animation-delay: 3.25s;
}

nav a {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  border-radius: 100%;
  border: solid 1px #003366;
  font-size: 1.75em;
  height: 2.5em;
  position: relative;
  top: 0;
  width: 2.5em;

.icon-class {
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 0 auto;
  width: 1em;
  height: 1em;
}

&:hover {
   background-color: rgba(255, 255, 255, 0.175);
   color: #fff;
 }

&:active {
   background: rgba(255, 255, 255, 0.35) none;
   color: #fff;
 }

.label {
  font-size: 0.45em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 0.5em;
  margin-top: 5px;
}
}
</style>
