<template>
  <div class="about-wrapper">
    <HeaderComponents :current-index="1" />
    <div class="about-content">
      <h1>关于我们</h1>
      <p>添实科技是一家以企业应用软件研发为主的科技公司, 以：</p>
      <ul>
        <li>
          <el-icon color="#04BE02"><WxIcon /></el-icon>微信小程序/微信公众号/企业微信
        </li>
        <li>
          <el-icon color="#005EFF"><DingdingIcon /></el-icon>钉钉小程序/<el-icon color="#1677FF"><AliPayIcon /></el-icon>支付宝小程序
        </li>
        <li>
          <el-icon color="#04BE02"><AndroidIcon /></el-icon>Android APP
        </li>
        <li>
          <MacIcon />苹果APP
        </li>
        <li>
          电脑软件
        </li>
        <li>
          <el-icon color="#FF5500"><Html5Icon /></el-icon>H5网站
        </li>
      </ul>
      <p>等多种软件应用形式，提供：</p>
      <ul>
        <li>
          广告宣传
        </li>
        <li>
          网络推广
        </li>
        <li>
          电子商务
        </li>
        <li>
          经营管理
        </li>
        <li>
          业务协作
        </li>
      </ul>
      <p>等多种信息化服务，全方位为企业的经营管理提供信息化赋能。</p>
    </div>
  </div>
</template>

<script>
  import AliPayIcon from "~icons/ri/alipay-fill"
  import DingdingIcon from "~icons/ri/dingding-fill"
  import WxIcon from "~icons/uiw/weixin"
  import AndroidIcon from "~icons/tabler/brand-android"
  import MacIcon from "~icons/iconoir/apple-mac"
  import Html5Icon from "~icons/mdi/language-html5"
  import HeaderComponents from "@/components/Layout/Header";
  export default {
    name: 'AboutPage',
    components: {HeaderComponents, AliPayIcon, DingdingIcon, WxIcon, AndroidIcon, MacIcon, Html5Icon },
    data() {
      return {}
    }
  }
</script>

<style lang="scss" scoped>
.about-wrapper {
  //height: 100%;
  //height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;

  .about-content {
    //width: 100%;
    position: relative;
    background: #88888850;
    padding: 20px;
    border-radius: 20px;

    //height: 100%;
    //overflow: auto;
    //display: flex;
    //flex-direction: column;
    //align-items: flex-start;
    //justify-content: center;
    margin: 130px auto 6em;

    h1 {
      font-size: 3em;
      font-weight: 900;
      letter-spacing: -0.035em;
      line-height: 1em;
      margin: 12px 0;
    }

    li {
      list-style: none;
    }
  }

}

/** 手机适配 开始 */
@media screen and (max-width: 480px) {
  .about-wrapper .about-content h1 {
    font-size: 2em;
  }
}
/** 手机适配 结束 */
</style>
