<template>
  <div class="service-wrapper">
    <HeaderComponents :current-index="2" />
    <div class="service-content">
      <h1>服务内容</h1>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12">
          <div class="grid-content grid-content-1 grid-content-left wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
            <h2>支付接入</h2>
            <p>添实科技是微信支付和支付宝支付的注册支付服务商，并通过了中国支付清算协会的收单外包服务机构备案，依托微信支付和支付宝支付两大支付平台，添实科技将为广大商家提供支付接入技术开发服务，包含两大支付平台的商户账号注册申请/优惠费率申请/收款码开通/收款接口开发整合等各类涉及支付相关的技术开发服务。</p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12">
          <div class="grid-content grid-content-2 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
            <h2>小程序/APP/H5网站/微信公众号开发</h2>
            <p>添实科技有丰富的小程序开发经验，包含平台类电商系统/网店类电商系统/分销系统/社区团购类系统/O2O线上预订线下核销系统等</p>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12">
          <div class="grid-content grid-content-left grid-content-3 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
            <h2>进销存软件开发</h2>
            <p>包含商户收银系统/库存管理系统/供应链管理系统/客户关系管理系统/会员管理系统等</p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12">
          <div class="grid-content grid-content-4 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
            <h2>协同管理软件开发</h2>
            <p>包含工作流/文件、表单下发审批流转/文件归档管理/生产调度、排班排产系统等</p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import HeaderComponents from "@/components/Layout/Header";
  import WOW from 'wow.js'

  export default {
    name: 'ServicePage',
    components: {HeaderComponents },
    data() {
      return {
        grid: [
          {
            me: false,
            ml: true
          },
          {
            me: false,
            ml: true
          },
          {
            me: false,
            ml: true
          },
          {
            me: false,
            ml: true
          }
        ]
      }
    },
    mounted() {
      const wow = new WOW({
        boxClass: "wow", // animated element css class (default is wow)
        animateClass: "animated", // animation css class (default is animated)
        offset: 0, // distance to the element when triggering the animation (default is 0)
        mobile: true, // trigger animations on mobile devices (default is true)
        live: true, // act on asynchronously loaded content (default is true)
        callback: function (box) {
          // the callback is fired every time an animation is started
          // the argument that is passed in is the DOM node being animated
          console.log(box)
        },
        scrollContainer: null, // optional scroll container selector, otherwise use window,
        resetAnimation: true, // reset animation on end (default is true)
      });
      wow.init();
    }
  }
</script>

<style lang="scss" scoped>
.service-wrapper {
  //height: 100%;
  //height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  box-sizing: content-box;

  .service-content {
    position: relative;
    //background: #88888850;
    //padding: 20px;
    border-radius: 20px;
    padding: 20px;
    //height: 100%;
    //overflow: auto;
    //display: flex;
    //flex-direction: column;
    //align-items: flex-start;
    //justify-content: center;
    margin: 130px auto 6em;
    width: 100%;
    box-sizing: border-box;
    flex: 1;

    h1 {
      font-size: 3em;
      font-weight: 900;
      letter-spacing: -0.035em;
      line-height: 1em;
      margin: 12px 0;
    }

    .el-row {
      margin-bottom: 0px;
    }
    .el-row:last-child {
      margin-bottom: 0;
    }

    .el-col {
      border-radius: 4px;
      margin-bottom: 10px;
    }

    .grid-content {
      position:relative;
      border-radius: 4px;
      min-height: 220px;
      border: 1px solid #003366;
      color: #3b3b3b;
      padding: 15px;
      background-color: rgba(235, 241, 254, 0.7);
      //background: #ebf1fe url(@/assets/images/phone.png) no-repeat bottom right;

      h2 {
        font-size: 2em;
        font-weight: 400;
        color: #333;
        margin-bottom: 10px;
        position: relative;
      }

      p {
        //width: 80%;
        position: relative;
      }
    }

    .grid-content-left {
      //background: #ebf1fe url(@/assets/images/phone.png) no-repeat bottom left;
      h2 {
        text-align: right;
      }
      //p {
      //  float: right;
      //}
    }

    //.grid-content-1 {
    //  background: #ebf1fe url(@/assets/images/pay.png) no-repeat bottom left;
    //  background-size: contain;
    //}
    .grid-content-1:after{
      position:absolute;
      content:'';
      left:0;
      top:0;
      width:100%;
      height:100%;
      background: #ebf1fe url(@/assets/images/pay.png) no-repeat bottom left;
      background-size: contain;
      //opacity: 0.9;
      //filter: blur(1px);
      z-index: -1;
    }
    //.grid-content-2 {
    //  background: #ebf1fe url(@/assets/images/phone.png) no-repeat bottom right;
    //  background-size: contain;
    //}
    .grid-content-2:after{
      position:absolute;
      content:'';
      left:0;
      top:0;
      width:100%;
      height:100%;
      background: #ebf1fe url(@/assets/images/phone.png) no-repeat bottom right;
      background-size: contain;
      //opacity: 0.9;
      //filter: blur(1px);
      z-index: -1;
    }
    //.grid-content-3 {
    //  background: #ebf1fe url(@/assets/images/erp.png) no-repeat bottom left;
    //  background-size: contain;
    //}
    .grid-content-3:after{
      position:absolute;
      content:'';
      left:0;
      top:0;
      width:100%;
      height:100%;
      background: #ebf1fe url(@/assets/images/erp.png) no-repeat bottom left;
      background-size: contain;
      //opacity: 0.9;
      //filter: blur(1px);
      z-index: -1;
    }

    //.grid-content-4 {
      //background: #ebf1fe url(@/assets/images/xiezuo.png) no-repeat bottom right;
      //background: linear-gradient(rgba(235, 241, 254, 0.9), rgba(235, 241, 254, 0.9)), url(@/assets/images/xiezuo.png) no-repeat bottom right;
      //
      //background-size: contain;
      //background-color: rgba(235, 241, 254, 0.9);
    //}

    .grid-content-4:after{
      position:absolute;
      content:'';
      left:0;
      top:0;
      width:100%;
      height:100%;
      background: #ebf1fe url(@/assets/images/xiezuo.png) no-repeat bottom right;
      background-size: contain;
      //opacity: 0.9;
      //filter: blur(1px);
      z-index: -1;
    }
  }



  .grid-content:before{
    position:absolute;
    content:'';
    left:0;
    top:0;
    width:0px;
    height:100%;
    background-color: #84a4e7;
    opacity: 0.5;
    transition: all 1000ms ease;
  }

  .grid-content:hover::before{
    width:100%;
  }

  .grid-content:hover{
    border-color:#84A4E7;
  }
}

/** 手机适配 开始 */
@media screen and (max-width: 480px) {
  .service-wrapper {
    .service-content {
      h1 {
        font-size: 2em;
      }

      .grid-content {
        h2 {
          font-size: 1.5em;
        }
        p {
          font-size: 0.9em;
        }
      }
    }
  }
}
/** 手机适配 结束 */
</style>
