import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import animate from "animate.css"
import 'animate.css'
// 这块一定要加,否者会有部分动画无法执行
import 'animate.css/animate.compat.css'
import App from './App.vue'
import router from './router'

createApp(App)
  .use(router)
  .use(ElementPlus, { size: 'small', zIndex: 3000 })
  // .use(animate)
  .mount('#app')
