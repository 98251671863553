<template>
  <div class="error-wrapper">
    <header-components current-index="-1" />
    <h1>404</h1>
    <h2 class="title">页面没找到</h2>
    <p class="description">可能发生了一些错误或你访问的页面不存在.</p>
  </div>
</template>

<script>
  import HeaderComponents from "@/components/Layout/Header";
  export default {
    name: '404Page',
    components: {HeaderComponents}
  }
</script>
<style lang="scss" scoped>
.error-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 3em;
  }
}
</style>
