// import Vue from 'vue'
import { createRouter,createWebHashHistory } from 'vue-router'

import Index from '../views/Index'
import About from '../views/About'
import Service from '../views/Service'
import Error from '../views/404'
import Contact from '../views/Contact'

// Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      title: '添实科技欢迎您',
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: '关于我们-添实科技',
    },
  },
  {
    path: '/service',
    name: 'Service',
    component: Service,
    meta: {
      title: '服务内容-添实科技',
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      title: '联系我们-添实科技',
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: Error,
    meta: {
      title: '页面缺失-添实科技',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/error'
  }
]

// const index = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })
const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes, // `routes: routes` 的缩写
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
  window.scrollTo(0, 0)
});

export default router
