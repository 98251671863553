<template>
  <div class="app-wrapper">
    <main>
      <router-view v-slot="{ Component, route }">
        <transition name="scale-slide" mode="out-in">
          <component :is="Component" :key="route.path" />
        </transition>
      </router-view>
      <!--<router-view />-->
    </main>
    <footer class="footer">
      <span class="copyright">© 2023
        <a href="http://www.tnxlive.cn/" target="_blank">TNX</a>
        丨
        <a href="http://beian.miit.gov.cn/" target="_blank" rel="nofollow">渝ICP备18016670号-1</a>
      </span>
    </footer>
  </div>
</template>

<script>
// import disableDevtool from 'disable-devtool';
// disableDevtool();
export default {
}
</script>

<style lang="scss">

html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  background: #003366;
  height: 100%;
  //overflow: hidden;
}

body, input, select, textarea {
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15pt;
  font-weight: 300 !important;
  letter-spacing: -0.025em;
  line-height: 1.75em;
}

a {
  transition: border-color 0.2s ease-in-out;
  border-bottom: dotted 1px;
  color: inherit;
  outline: 0;
  text-decoration: none;
}

a:hover {
  border-color: transparent;
}


.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex: 1;
    box-sizing: border-box;
  }

  footer {
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5) 75%);
    bottom: 0;
    cursor: default;
    height: 6em;
    left: 0;
    line-height: 8em;
    position: fixed;
    text-align: center;
    width: 100%;
  }
}

//main {
//  background: #003366;
//}


.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease;
}

.scale-slide-enter-from {
  left: -100%;
}

.scale-slide-enter-to {
  left: 0%;
}

.scale-slide-leave-from {
  transform: scale(1);
}

.scale-slide-leave-to {
  transform: scale(0.8);
}


@keyframes overlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.overlay {
  animation: overlay 1.5s 1.5s forwards;
  background-attachment: fixed, fixed;
  background-image: url(@/assets/images/overlay-pattern.png), url(@/assets/images/overlay.svg);
  background-position: top left, center center;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
}
</style>
