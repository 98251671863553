<template>
  <div class="contact-wrapper">
    <HeaderComponents :current-index="3" />
    <div class="contact-content">
      <h1>联系方式</h1>
      <p>欢迎垂询洽谈各类软件开发、企业信息化解决方案。</p>
      <ul>
        <li>
          <el-icon color="#04BE02"><Phone /></el-icon>19923810364
        </li>
        <li>
          <el-icon color="#04BE02"><Location /></el-icon>重庆市渝北区兰馨大道3号3-31
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import HeaderComponents from "@/components/Layout/Header";
  import {Location, Phone} from "@element-plus/icons-vue";
  export default {
    name: 'ContactPage',
    components: {Phone, Location, HeaderComponents },
    data() {
      return {}
    }
  }
</script>

<style lang="scss" scoped>
.contact-wrapper {
  //height: 100%;
  //height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;

  .contact-content {
    //width: 100%;
    position: relative;
    background: #88888850;
    padding: 20px;
    border-radius: 20px;

    //height: 100%;
    //overflow: auto;
    //display: flex;
    //flex-direction: column;
    //align-items: flex-start;
    //justify-content: center;
    margin: 130px auto 6em;

    h1 {
      font-size: 3em;
      font-weight: 900;
      letter-spacing: -0.035em;
      line-height: 1em;
      margin: 12px 0;
    }

    li {
      list-style: none;
    }
  }

}

/** 手机适配 开始 */
@media screen and (max-width: 480px) {
  .contact-wrapper .contact-content h1 {
    font-size: 2em;
  }
}
/** 手机适配 结束 */
</style>
